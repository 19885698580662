import {createApp} from 'vue'
import App         from './App.vue'
import router      from './router'
import auth        from "@/store/auth.js";

import PrimeVue        from 'primevue/config';
import CascadeSelect   from "primevue/cascadeselect/CascadeSelect";
import AutoComplete    from "primevue/autocomplete/AutoComplete";
import InputNumber     from 'primevue/inputnumber';
import Button          from "primevue/button/Button";
import Menubar         from 'primevue/menubar';
import DataTable       from "primevue/datatable/DataTable";
import Column          from 'primevue/column';
import Dropdown        from 'primevue/dropdown';
import Message         from 'primevue/message';
import ProgressBar     from "primevue/progressbar/ProgressBar";
import Dialog          from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import InputText       from 'primevue/inputtext';
import Textarea        from 'primevue/textarea';

import UIHelper from "@/mixins/UIHelper";

import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import '@/assets/css/presentation.css';
import 'uikit/dist/css/uikit.min.css';

import 'uikit';

let app = createApp(App);

app.use(router, PrimeVue);
app.use(auth);

app.component('CascadeSelect', CascadeSelect)
app.component('Dropdown', Dropdown)
app.component('AutoComplete', AutoComplete)
app.component('InputNumber', InputNumber)
app.component('Button', Button)
app.component('Menubar', Menubar)
app.component('DataTable', DataTable)  // For table required DataTable and
app.component('Column', Column)        // column
app.component('Message', Message)
app.component('ProgressBar', ProgressBar)
app.component('Dialog', Dialog)
app.component('ProgressSpinner', ProgressSpinner)
app.component('InputText', InputText)
app.component('Textarea', Textarea)


app.config.globalProperties.$filters = {
    unixToHuman: (value) => value ? new Date(value).toLocaleString() : '', // todo maybe date helper
    percentage : (curr, total) => Math.floor(curr / total * 100)
}

app.mixin(UIHelper);

app.mount('#app')

