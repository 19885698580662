<template>
  <div>
    <Menubar v-if="showNav" class="p-mb-3" :model="items"/>
  </div>
  <router-view/>
</template>


<script>
import Menubar from "primevue/menubar/Menubar";

export default {
  components: {
    Menubar: Menubar
  },
  mounted() {
    this.items = [
      {
        label: 'Главная',
        to   : "/",
      },
      {
        label  : 'КП строительства дорог',
        items  : [
          {label: 'Просмотр', to: "/roads/list"},
          {label: 'Создать', to: "/roads/generate"}
        ],
        visible: () => this.$auth.check(),
      },
      {
        label  : 'КП участков',
        items  : [
          {label: 'Просмотр', to: "/batch/list"},
          {label: 'Создать', to: "/batch/generate"},
          {label: 'Инструкция', url: 'https://youtu.be/LS2UwNkeT84'}
        ],
        visible: () => this.$auth.check(),
      },
      {
        label  : 'КП межевые знаки',
        items  : [
          {label: 'Просмотр', to: "/sign/list"},
          {label: 'Создать', to: "/sign/generate"}
        ],
        visible: () => this.$auth.check(),
      },
      {
        label  : 'КП СХ',
        items  : [
          {label: 'Просмотр', to: "/agro/list"},
          {label: 'Создать', to: "/agro/generate"}
        ],
        visible: () => this.$auth.check(),
      },
      {
        label  : 'Войти',
        to     : '/auth/logout',
        visible: () => !this.$auth.check(),
      },
      {
        label  : 'Выйти',
        to     : '/auth/logout',
        visible: () => this.$auth.check(),
      }
    ];
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    showNav() {
      let routesWithoutMenu = [
        'batch-presentation',
        'map-presentation',
        'roads-presentation'
      ];

      return !routesWithoutMenu.includes(this.$route.name) || !this.$route.name
    }
  }
}

</script>
