import {createRouter, createWebHistory} from 'vue-router'
import Home                             from '../views/Home.vue'

const routes = [
    {
        path     : '/',
        name     : 'home',
        component: Home
    },
    {
        path: '/auth/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/auth/LoginComponent'),
        meta     : {auth: false}
    },
    {
        path: '/auth/logout',
        name: 'logout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/components/auth/LogoutComponent'),
        meta     : {auth: true}
    },
    {
        path: '/roads/generate',
        name: 'roads-generate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/components/roads/RoadsGenerateComponent'),
        meta     : {auth: {roles: 'Offers_Administrator'}}
    },
    {
        path     : '/roads/list',
        name     : 'roads-list',
        component: () => import(/* webpackChunkName: "about" */ '@/components/roads/RoadsOffersListComponent'),
        meta     : {auth: {roles: 'Offers_Administrator'}}
    },
    {
        path     : '/roads/presentation',
        name     : 'roads-presentation',
        component: () => import(/* webpackChunkName: "about" */ '@/components/catalog/RoadsPresentationComponent'),
        props    : route => ({
            entranceId: route.query.entranceId,
            revisionId: route.query.revisionId,
            counterpartyId: route.query.counterpartyId,
        })
    },
    {
        path: '/batch/generate',
        name: 'batch-generate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/components/catalog/BatchGenerateComponent'),
        meta     : {auth: {roles: 'Offers_Administrator'}}
    },
    {
        path     : '/batch/list',
        name     : 'batch-list',
        component: () => import(/* webpackChunkName: "about" */ '@/components/catalog/BatchOffersListComponent'),
        meta     : {auth: {roles: 'Offers_Administrator'}}
    },
    {
        path     : '/batch/presentation',
        name     : 'batch-presentation',
        component: () => import(/* webpackChunkName: "about" */ '@/components/catalog/UserPresentationComponent'),
        props    : route => ({article: route.query.article})
    },
    {
        path     : '/map/presentation',
        name     : 'map-presentation',
        component: () => import(/* webpackChunkName: "about" */ '@/components/catalog/MapPresentationComponent.vue'),
        props    : route => ({mapId: route.query.mapId})
    },
    {
        path     : '/sign/list',
        name     : 'sign-list',
        component: () => import(/* webpackChunkName: "about" */ '@/components/sign/SignOffersListComponent'),
        meta     : {auth: {roles: 'Offers_Administrator'}}
    },
    {
        path     : '/agro/list',
        name     : 'agro-list',
        component: () => import(/* webpackChunkName: "about" */ '@/components/agro/AgroOffersListComponent'),
        meta     : {auth: {roles: 'Offers_Administrator'}}
    },
    {
        path     : '/agro/generate',
        name     : 'agro-generate',
        component: () => import(/* webpackChunkName: "about" */ '@/components/agro/AgroGenerateComponent'),
        meta     : {auth: {roles: 'Offers_Administrator'}}
    },
    {
        path: '/sign/generate',
        name: 'sign-generate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/components/sign/SignGenerateComponent'),
        meta     : {auth: {roles: 'Offers_Administrator'}}
    },
    {
        path     : "/403",
        component: () => import(/* webpackChunkName: "about" */ '@/components/Forbidden'),
    },
    {
        path     : "/:catchAll(.*)",
        component: () => import(/* webpackChunkName: "about" */ '@/components/NotFound'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


export default router
